div.ag-theme-material, .ag-header-cell-text, .ag-text-field-input {
  font-size: 16px !important;
}

.pr-news-ticker {
  display: none !important;
}

#mod-form-singlesign .rmd-password__toggle {
  display: none !important;
}

.mpk-appbar-shortcut {
  display: none !important;
}

window, body {
    zoom: 80%;
    scale: 1;
    -moz-transform: scale(1);
}
  
.mpk-full.viewport-height {
    height: 100% !important;
}
  
.mpk-full.viewport-width {
    width: 100% !important;
}

._loading_overlay_wrapper {
    position: static !important;
}

._loading_overlay_overlay {
    position: fixed !important;
}

.chip-green {
    background: #4CAF50;
    color: white !important;
}

.chip-red {
    background: #F44336;
    color: white !important;
}

.dialogInduk {
    width: 600px !important;
}

.progress{
  width : 200px !important;
}

.totalBold input {
    font-weight: bold !important;
}

.inputRight {
  text-align: right;
}

.react-datepicker-popper {
    z-index: 9999;
}

$gradient-primary-2:#eee;
$gradient-primary-1:#e2e2e2;

.account{
    background: #e2e2e2;
    overflow-x:hidden;
    position:relative;
    .bg{
      position:fixed;
      overflow:hidden;
      width:100%;
      height:100%;
      z-index: 0;
      .parallelogram-1 {
        position:absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity:.54;
        -webkit-transform: skew(56deg);
        -moz-transform: skew(56deg);
        -o-transform: skew(56deg);
        background: $gradient-primary-1; /* Old browsers */
        background: -moz-linear-gradient(top, $gradient-primary-1 56%, $gradient-primary-2 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, $gradient-primary-1 56%,$gradient-primary-2 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, $gradient-primary-1 56%,$gradient-primary-2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 ); /* IE6-9 */
      }
      .parallelogram-2 {
        position:absolute;
        top:0;
        right:0;
        width: 50%;
        height: 100%;
        opacity:1;
        -webkit-transform: skew(56deg);
        -moz-transform: skew(56deg);
        -o-transform: skew(56deg);
        background: $gradient-primary-2; /* Old browsers */
        background: -moz-linear-gradient(top, $gradient-primary-1 0%, $gradient-primary-2 24%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, $gradient-primary-1 0%,$gradient-primary-2 24%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, $gradient-primary-1 0%,$gradient-primary-2 24%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 ); /* IE6-9 */
      }
      .parallelogram-3 {
        position:absolute;
        top:0;
        left:0%;
        opacity:.24;
        width: 18%;
        height: 100%;
        -webkit-transform: skew(-12deg);
        -moz-transform: skew(-12deg);
        -o-transform: skew(-12deg);
        background: $gradient-primary-2; /* Old browsers */
        background: -moz-linear-gradient(top, $gradient-primary-1 0%, $gradient-primary-2 52%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, $gradient-primary-1 0%,$gradient-primary-2 52%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, $gradient-primary-1 0%,$gradient-primary-2 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 ); /* IE6-9 */
      }
      .parallelogram-4 {
        position:absolute;
        top:0;
        right:30%;
        opacity:.32;
        width: 40%;
        height: 100%;
        -webkit-transform: skew(-12deg);
        -moz-transform: skew(-12deg);
        -o-transform: skew(-12deg);
        background: $gradient-primary-2; /* Old browsers */
        background: -moz-linear-gradient(top, $gradient-primary-2 0%, $gradient-primary-1 52%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, $gradient-primary-2 0%,$gradient-primary-1 52%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, $gradient-primary-2 0%,$gradient-primary-1 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-2', endColorstr='$gradient-primary-1',GradientType=0 ); /* IE6-9 */
      }
      .parallelogram-5 {
        position:absolute;
        top:0;
        right:5%;
        opacity:.4;
        width: 40%;
        height: 100%;
        -webkit-transform: skew(-12deg);
        -moz-transform: skew(-12deg);
        -o-transform: skew(-12deg);
        background: $gradient-primary-2; /* Old browsers */
        background: -moz-linear-gradient(top, $gradient-primary-2 0%, $gradient-primary-1 52%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, $gradient-primary-2 0%,$gradient-primary-1 52%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, $gradient-primary-2 0%,$gradient-primary-1 52%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-2', endColorstr='$gradient-primary-1',GradientType=0 ); /* IE6-9 */
      }
    }
}

//Tags Input

.tags {
  display: run-in;
  width: 100%;
  height: auto;
  max-width: 100%;
  padding-left: 14px;
  color: black;
  padding-top: 14px;
}

.tags input {
  width: 100%;
  min-width: 50%;
  border: none;
  border-radius: 5px;
  // padding: 14px;
  // padding-left: 0px;
  font-size: 15px;
  color : black;
}

.tags input:focus {
  outline: none !important;
  border:1px solid dodgerblue;
  box-shadow: 0 0 5px #719ECE;
}

.tag {
  background-color: tomato;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
  color: white;
}

.tag button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  margin: 0;
  font-size: 90%;
  color: red;
}

input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 20px;
  font-size: 17px;
  color: #b8b8b8;
}
.button-wrap {
  position: relative;
}
.button {
  display: inline-block;
  background-color: darkorchid;
  border-radius: 8px;
  border: 1px solid darkorchid;
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  padding: 8px;
  width: 100px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}
.button:hover {
  background-color: darkorchid;
}

.menu-icon {
  position: static;
  width: 67px;
  height: 70px;
  left: 0px;
  top: 0px;
  display: flex;
  margin-right: 0px;
}

.menu-list {
  pointer-events: auto;
  cursor: pointer;
  padding: 0px 16px 0px 0px;
  position: static;
  width: 312px;
  height: 70px;
  left: 24px;
  top: 144px;
  background: #FFFFFF;
  /* Dark / Dark 0 */

  border: 1px solid #1C1C28;
  box-sizing: border-box;

  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
  border-radius: 5px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
}

.heading-3 {
  margin: 5px 0px;
}

.rata-kiri-text {
  margin-left: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  align-items: center;
  letter-spacing: 0.004em;
}

.rata-kiri-sub{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  letter-spacing: 0.0025em;
  color: #555770;
}

.rata-kiri {
  margin-left: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 0.004em;

  /* Dark / Dark 2 */

  color: #555770;
}

.sidebar-spt {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow-y: scroll;
}

.sidebar-spt::-webkit-scrollbar {
  display: none;
}

.calender-spt {
  width: 312px !important;

  /* Light / Light 4 */
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.004em;

  /* Dark / Dark 2 */

  color: #555770;

  
  background: #FFFFFF;
  /* Dark / Dark 0 */

  border: 1px solid #1C1C28;
  box-sizing: border-box;
  /* Light/Elevation 06 */

  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 5px;

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 24px 0px;
  margin-bottom: 0px;
}

.today-calender {
  background: #555770;
  border-radius: 40px;
  color : #ffffff  !important;
}

.today-calender::after {
  visibility: hidden; /* hide small border under the text */
}

.select-month-custom{
  position: static;
  width: 80px;
  height: 40px;
  left: calc(50% - 80px/2 - 50px);
  top: calc(50% - 40px/2);

  /* Light / Light 0 */

  background: #E4E4EB;
  border-radius: 8px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */


  /* Dark / Dark 2 */

  color: #555770;


  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.select-year-custom{
  position: static;
  width: 80px;
  height: 40px;
  left: calc(50% - 80px/2 - 50px);
  top: calc(50% - 40px/2);

  /* Light / Light 0 */

  background: transparent;
  border-radius: 8px;
  border: transparent;
  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */


  /* Dark / Dark 2 */

  color: #555770;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.custom-headers{
  width: 310px !important;
}

.react-datepicker__navigation {
  border: 0;
}

.day-custom {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.004em;

  /* Dark / Dark 2 */

  color: #555770;
}

.week-custom {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.0125em;

  /* Dark / Dark 2 */

  color: #555770;
}

.navigation-date{
  margin: 8.33%;
  background: #555770;
  color: white;
  border : 0;
  border-radius: 50%;
}

.date-list {
  margin: 0px;
}

.mpk-full{
  &.full-height{
    &.dashboard-costum{
      width: 99% !important;
      margin-left: 10px;
      margin-bottom: 12px;
      // flex: 1 !important;
    }
  }
}
.signatureCanvas {
  border: 1px solid black;
  width: 100% !important;
  height: 300px !important;
  zoom: 125%;
  scale: 1;
  -moz-transform: scale(1);
}

// EMO-567
.rmd-option--selected:after {
  left: 0.5rem;
  content: none !important;
  position: absolute;
}

.user-select-none .svg-container {
  zoom: 125%;
  scale: (1);
  -moz-transform: scale(1);
}

#qm-inline{
  border: thin solid #F3F0ED !important;
}

.rdw-editor-wrapper{
  min-height: 300px;
}
.ql-container{
  min-height: 300px;
}

.placeholder-ul{
  visibility: hidden;
}
.placeholder-li:hover {
  background: #F1F1F1;
}

// Tambahan Uci, React-Select
.filter__control{
  height: auto !important;
}

.css-g1d714-ValueContainer{
  height: auto !important;
}

.select-custom{
  width: 100%;
  max-width: calc(100% - 50%) !important;
}

// Tambahan Uci, React-Select
